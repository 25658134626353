import api from './api/api';



class SubmitService {
    push(sentiment, bothering, comment, badgeid, roomnumber) {
        return api.post('/kiosk/demo/submit/', {
            sentiment: sentiment,
            bothering: bothering,
            comment: comment,
            badgeid: badgeid,
            roomnumber: roomnumber
        });
    }
}

export default new SubmitService();