import { useState, useEffect, useRef } from 'react'
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import "../assets/css/kluskip.css"

//controllers
import SoundController from '../controllers/sound.controller';

//services
import SubmitService from '../services/submit.service';

//components
import ScanComponent from '../components/scan/scan.component';
import RoomNumberComponent from '../components/scan/roomnumber.component';
import ThanksComponent from '../components/thanks.component';
import LostComponent from '../components/lost.component';
import WonComponent from '../components/won.component';

//data stores
import { badgeKeyState } from '../data/badgeKey.atom';
import { loadingState } from '../data/loading.atom';
import { errorMessageState } from '../data/errorMessage.atom';

//images
import BotheringBG from '../assets/img/bg/bothering-bg.png';
import ContinueArrow from '../assets/img/diagram/continue-arrow.png';
import BackArrow from '../assets/img/diagram/back-arrow.png';


export default function KlusKipView() {
    const navigate = useNavigate();

    const [step, setStep] = useState(0)

    const [badgeKey, setBadgeKey] = useRecoilState(badgeKeyState);
    const [roomNumber, setRoomNumber] = useState(null)
    const [reload, setReload] = useState(0)


    const [comment, setComment] = useState("")

    // dropdown
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);
    const categories = ["Deuren en ramen", "Eigen toilet", "Eigen douche", "Elektriciteit", "Elektrische keukentoestellen", "Gemeenschappelijk sanitair", "Internet", "Laundry", "Lift", "Look & feel (decoratie)", "Meubilair", "Verwarming", "Ventilatie", "Anders..."]

    const [category, setCategory] = useState("")
    const [customCategory, setCustomCategory] = useState("")

    // win logic
    const [itemWon, setItemWon] = useState(null)

    const [loading, setLoading] = useRecoilState(loadingState);
    const [errorMessage, setErrorMessage] = useRecoilState(errorMessageState);

    function selectCategory(id) {
        setCategory(id)
        setDropdownOpen(false)
    }

    useEffect(() => {
        if (dropdownOpen === true) {
            setCategory("")
            setCustomCategory("")
        } else {
            if (customCategory) {
                setCategory(customCategory)
            }
        }
    }, [dropdownOpen])

    function thanksChick() {
        SoundController.beep();
        if (itemWon) {
            setStep(4)
        } else {
            setStep(3)
        }
    }

    useEffect(() => {
        if (badgeKey.length === 8) {
            setLoading("yes");
            SubmitService.push("KLUS", category, comment, badgeKey, roomNumber)
                .then((response) => {
                    setLoading("no");
                    if (response.data.message === "OK") {
                        var resp = response.data
                        if (resp.won == "True") {
                            setItemWon(resp.item)
                        } 
                        setStep(2)
                    } 
                })
                .catch((error) => {
                    setLoading("error");
                    setErrorMessage(error.response.data.message);
                })
        }
    }, [badgeKey, reload])

    function RoomNumberFunc(room) {
        setRoomNumber(room)
        setStep(1)
    }

    function clearSession() {
        window.location.href = "/"
    }

    useEffect(() => {
        let timeoutId;
    
        if (step === 3 || step === 4) {
            timeoutId = setTimeout(() => {
                clearSession();
            }, 30000);
        } else if (step === 2) {
            timeoutId = setTimeout(() => {
                if (itemWon) {
                    setStep(4);
                } else {
                    setStep(3);
                }
            }, 20000);
        } else if (step === 0 || step === 1 || step === 5) {
            timeoutId = setTimeout(() => {
                clearSession();
            }, 60000);
        }
    
        // Cleanup function
        return () => {
            clearTimeout(timeoutId);
        };
    }, [step]);
    

    return (
        <>
            {step === 0 &&
                <>
                    <img src={BotheringBG} className="bothering-bg" />

                    <div className='header header-happy'>
                        <div className='title text-center title-happy'>
                            What can we<br />
                            <span className="important-text">fix</span> for you?
                        </div>
                    </div>

                    <div className="kluskip-content">
                        <p>Choose a category</p>
                        <div className="flex justify-center dropdown">
                            <div className={dropdownOpen ? "dropbtn-open dropbtn" : "dropbtn"}onClick={toggleDropdown}>{category ? category : "- select a category -"}</div>
                            {dropdownOpen &&
                            <div className="dropdown-content">
                                {categories.map((category, index) => {
                                    return (
                                        <a key={index} onClick={() => selectCategory(category)}>{category}</a>
                                    )
                                })}
                                {/*<input
                                    type="text"
                                    className="dropdown-input"
                                    placeholder="Other..."
                                    value={customCategory}
                                    onChange={(e) => setCustomCategory(e.target.value)}
                                    onKeyDown={(e) => { if (e.key === 'Enter') { setDropdownOpen(false) } }}
                                />*/}
                            </div>
                            }
                        </div>
                        <p className='comment-box'>Describe your problem</p>
                        <textarea
                            type="text"
                            className="input-comment mt-6 input-comment-kluskip"
                            placeholder="Start typing..."
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />

                    </div>

                    <div className="flex justify-center button-block button-block-sad">
                        <div className='back-button' onClick={() => navigate("/")}>
                            <img src={BackArrow} className="continue-arrow" />
                            <p className='back-button-text'>previous</p>
                        </div>
                        <div className='continue-button' onClick={() => setStep(6)}>
                            <p className='continue-button-text'>continue</p>
                            <img src={ContinueArrow} className="continue-arrow" />
                        </div>
                    </div>
                </>
            }

            {step === 1 &&
                <div>
                    <ScanComponent />
                </div>
            }

            {step === 2 &&
                <div>
                    <ThanksComponent chick={thanksChick} />
                </div>
            }

            {step === 3 &&
                <div>
                    <LostComponent />
                </div>
            }

            {step === 4 &&
                <div>
                    <WonComponent item={itemWon} />
                </div>
            }

            {/* {step === 5 &&
                <div>
                    <BadgeComponent badgeNumberFunc={badgeNumberFunc} badgeId={badgeKey} />
                </div>
            } */}

            {step === 6 &&
                <div>
                    <RoomNumberComponent RoomNumberFunc={RoomNumberFunc} />
                </div>
            }
        </>
    )
}