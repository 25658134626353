class SoundController {
    beep() {
        const beep = new Audio('./sounds/effects/click.wav');
        beep.play();
    }

    loading() {
        const loadingsound = new Audio('./sounds/effects/loading.wav');
        loadingsound.play();
    }   

    error() {
        const errorsound = new Audio('./sounds/effects/error.wav');
        errorsound.play();
    }

    thanks() {
        const thanks = new Audio('./sounds/effects/thanks.wav');
        thanks.play();
    }

    won() {
        const won = new Audio('./sounds/effects/won.wav');
        won.play();
    }

    lost() {
        const lost = new Audio('./sounds/effects/lost.wav');
        lost.play();
    }

    idling() {
        const idling = new Audio('./sounds/effects/idling.wav');
        idling.play();
    }
}

export default new SoundController();