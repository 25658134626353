import React, {useEffect} from "react";
import "../assets/css/lost.css";

//images
import SurpriseBG from '../assets/img/bg/surprise-bg.png';
import LostChick from '../assets/img/lost-chick.png';

// controllers
import SoundController from '../controllers/sound.controller';


export default function LostComponent() {

    // play sound when loaded
    useEffect(() => {
        setTimeout(() => {
            SoundController.lost();
        }, 1000);
    }, []);

    return (
        <>
            <img src={SurpriseBG} className="" />
            <img src={LostChick} className="lost-chick" />


            <div className='header header-lost'>
                <div className='title text-center'>
                    uh oh, no<br />
                    <span className="important-text">golden egg</span> today
                </div>
                <div className='subtitle text-center'>
                Try again tomorrow!
                </div>
            </div>

        </>
    );
}