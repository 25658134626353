import React, {useEffect} from "react";
import "../assets/css/won.css";

//images
import SurpriseBG from '../assets/img/bg/surprise-bg.png';
import GoldenEgg from '../assets/img/bg/goldenegg-big.png';

// controllers
import SoundController from '../controllers/sound.controller';

export default function WonComponent({ item }) {

    // play sound when loaded
    useEffect(() => {
        setTimeout(() => {
            SoundController.won();
        }, 1000);
    }, []);

    return (
        <>
            <img src={SurpriseBG} className="" />
            <img src={GoldenEgg} className="won-egg" />


            <div className='header header-lost'>
                <div className='title text-center'>
                    Congrats! you win a<br />
                    <span className="important-text">{
                        item ? item : "nothing"
                    }</span>
                </div>
                <div className='subtitle text-center'>
                    Your coach will contact you soon. <br />
                    Thanks and see you next time!
                </div>
            </div>

        </>
    );
}