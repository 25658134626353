import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/badgenumber.css"

//images
import BadgeScanBG from '../../assets/img/bg/badgescan-bg.png';
import Badge from '../../assets/img/scan/badge-two.png';
import ContinueArrow from '../../assets/img/diagram/continue-arrow.png';
import BackArrow from '../../assets/img/diagram/back-arrow.png';

export default function RoomNumberComponent({ RoomNumberFunc }) {
    const [roomNumber, setRoomNumber] = useState("");


    return (
        <>
            <img src={BadgeScanBG} className="bothering-bg" />
            <img src={Badge} className="badge" />

            <div className="title text-center title-badgenumber">
                YOUR ROOM?
            </div>
            <div className="subtitle text-center">
                Insert the number of your room<br />
            </div>

            <input
                className="border-2 border-gray-500 rounded-md p-2 badgenumber-input"
                type="number"
                placeholder="Enter your room number"
                value={roomNumber}
                onChange={(e) => setRoomNumber(e.target.value)}
            />


            <div className="flex justify-center mt-16 button-block button-block-badgenumber">
                <div className='back-button' onClick={() => window.location.href = "/"}>
                    <img src={BackArrow} className="continue-arrow" />
                    <p className='back-button-text'>cancel</p>
                </div>
                <div className='continue-button' onClick={() => RoomNumberFunc(roomNumber)}>
                    <p className='continue-button-text'>continue</p>
                    <img src={ContinueArrow} className="continue-arrow" />
                </div>
            </div>
        </>
    );
}