import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import "../../assets/css/scan.css";

//controllers
import SoundController from '../../controllers/sound.controller';

//data stores
import { badgeKeyState } from '../../data/badgeKey.atom';

//images
import ScanBG from '../../assets/img/bg/scan-bg.png';
import StripesBG from '../../assets/img/bg/stripes.png';
import BadgeImg from '../../assets/img/scan/badge.png';
import ArrowOne from '../../assets/img/scan/arrow1.png';
import ArrowTwo from '../../assets/img/scan/arrow2.png';

export default function ScanComponent() {
    const [badgeKey, setBadgeKey] = useRecoilState(badgeKeyState);

    useEffect(() => {
        // Set a timeout to refresh the page every 2 minutes (120000 milliseconds)
        const timeoutId = setTimeout(() => {
            window.location.href = "/";
            
        }, 120000); // 120000 ms = 2 minutes
        
        // Clear the timeout if the component is unmounted before the timeout fires
        return () => clearTimeout(timeoutId);
      }, []);  // Empty dependency array means this useEffect runs once, similar to componentDidMount

    function handleKeyPress(event) {
        const keyMap = {
            "&": "1",
            "é": "2",
            '"': "3",
            "'": "4",
            "(": "5",
            "§": "6",
            "è": "7",
            "!": "8",
            "ç": "9",
            "à": "0"
        };
    
        let key = keyMap[event.key] || event.key;
        

        if (!isNaN(key)) {
            if (badgeKey.length < 8) {
                setBadgeKey(badgeKey + key)
            } else {
                setBadgeKey(key)
            }
        }
    }

    useEffect(() => {
        window.addEventListener("keypress", handleKeyPress)
        return () => {
            window.removeEventListener("keypress", handleKeyPress)
        }        
    }, [badgeKey])


    return (
        <>
            <img src={ScanBG} className="" />
            <div className="title title-scan text-center">
                BIEP BIEP
            </div>
            <div className="subtitle text-center">
                Time to register your answers and feedback.<br/>
                Scan your Upkot badge underneath the screen right in between the ‘scan’ sign on the kiosk.
                <br/>
                <br/>
                You might win cool prizes!
            </div>
            <div className='circles text-center'>
                <img src={BadgeImg} className="badge-img" />
                <div className="circle-one"></div>
                <div className="circle-two"></div>
                <div className="circle-three"></div>
                <div className="circle-four"></div>
            </div>
            <img src={ArrowOne} className="arrow-one" />
            <img src={ArrowTwo} className="arrow-two" />
            <img src={StripesBG} className="stripes-bg" />
        </>
    )
}