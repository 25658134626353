import { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../assets/css/home.css';
import '../assets/css/happy.css';

//controllers
import SoundController from '../controllers/sound.controller';

//services
import SubmitService from '../services/submit.service';

//components
import ScanComponent from '../components/scan/scan.component';
//import BadgeComponent from '../components/scan/badge.component';
import RoomNumberComponent from '../components/scan/roomnumber.component';
import ThanksComponent from '../components/thanks.component';
import LostComponent from '../components/lost.component';
import WonComponent from '../components/won.component';

//data stores
import { badgeKeyState } from '../data/badgeKey.atom';
import { loadingState } from '../data/loading.atom';
import { errorMessageState } from '../data/errorMessage.atom';

//images
import HappyBG from '../assets/img/bg/happy-bg.png';
import ContinueArrow from '../assets/img/diagram/continue-arrow.png';
import BackArrow from '../assets/img/diagram/back-arrow.png';

export default function GoodKipView() {
    const [step, setStep] = useState(0)
    const [badgeKey, setBadgeKey] = useRecoilState(badgeKeyState);
    const [roomNumber, setRoomNumber] = useState(null)
    const [comment, setComment] = useState("")
    const [reload, setReload] = useState(0)
    
    const [itemWon, setItemWon] = useState(null)

    const [loading, setLoading] = useRecoilState(loadingState);
    const [errorMessage, setErrorMessage] = useRecoilState(errorMessageState);
    
    const navigate = useNavigate();

    useEffect(() => {
        if (badgeKey.length === 8) {
            setLoading("yes")
            SubmitService.push("HAPPY", null, comment, badgeKey, roomNumber)
                .then((response) => {
                    setLoading("no")
                    if (response.data.message === "OK") {
                        var resp = response.data
                        if (resp.won == "True") {
                            setItemWon(resp.item)
                        } 
                        setStep(2)
                    } 
                })
                .catch((error) => {
                    setLoading("error")
                    setErrorMessage(error.response.data.message)
                })
        }
    }, [badgeKey, reload])

    function thanksChick() {
        SoundController.beep();
        if (itemWon) {
            setStep(4)
        } else {
            setStep(3)
        }
    }

    function RoomNumberFunc(room) {
        setRoomNumber(room)
        setStep(1)
    }

    function clearSession() {
        window.location.href = "/"
    }

    useEffect(() => {
        let timeoutId;
    
        if (step === 3 || step === 4) {
            timeoutId = setTimeout(() => {
                clearSession();
            }, 30000);
        } else if (step === 2) {
            timeoutId = setTimeout(() => {
                if (itemWon) {
                    setStep(4);
                } else {
                    setStep(3);
                }
            }, 20000);
        } else if (step === 0 || step === 1 || step === 5 || step === 6) {
            timeoutId = setTimeout(() => {
                clearSession();
            }, 60000);
        }
    
        // Cleanup function
        return () => {
            clearTimeout(timeoutId);
        };
    }, [step]);

    return (
        <>
            {step === 0 &&
                <>
                    <img src={HappyBG} className="" />
                    <div className='header header-happy'>
                        <div className='title text-center title-happy'>
                            Glad to hear<br />
                            you're <span className="important-text">happy</span>
                        </div>
                    </div>

                    <div className="justify-center text-center content">
                        <div className='subtext'>
                            Would you like to tell us more about it?
                        </div>
                        <textarea
                            type="text"
                            className="input-comment mt-6"
                            placeholder="Start typing..."
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </div>

                    <div className="flex justify-center mt-16 button-block">
                        <div className='back-button' onClick={() => navigate("/")}>
                        <img src={BackArrow} className="continue-arrow" />
                            <p className='back-button-text'>previous</p>
                        </div>
                        <div className='continue-button' onClick={() => setStep(6)}>
                            <p className='continue-button-text'>continue</p>
                            <img src={ContinueArrow} className="continue-arrow" />
                        </div>
                    </div>
                </>
            }

            {step === 1 &&
                <div>
                    <ScanComponent />
                </div>
            }

            {step === 2 &&
                <div>
                    <ThanksComponent chick={thanksChick} />
                </div>
            }

            {step === 3 &&
                <div>
                    <LostComponent />
                </div>
            }

            {step === 4 &&
                <div>
                    <WonComponent item={itemWon} />
                </div>
            }

            {/* {step === 5 &&
                <div>
                    <BadgeComponent badgeNumberFunc={badgeNumberFunc} badgeId={badgeKey} />
                </div>
            } */}

            {step === 6 &&  
                <div>
                    <RoomNumberComponent RoomNumberFunc={RoomNumberFunc} />
                </div>
            }
        </>
    )
}
