import React, { useRef, useEffect, useState } from 'react';
import '../assets/css/home.css';
import '../assets/css/button.css';
import '../assets/css/slidein.css';
import { useNavigate } from 'react-router-dom';

//controllers
import SoundController from '../controllers/sound.controller';

//images
import { ReactComponent as HappyChick } from '../assets/img/happy.svg';
import { ReactComponent as SadChick } from '../assets/img/sad.svg';

import HomeBG from '../assets/img/bg/home-bg.png';
import WinBG from '../assets/img/bg/win.png';
import WinEgg from '../assets/img/bg/goldenegg.png';

import Confess from '../assets/img/thanks-chick-confess.png';

import Tools from '../assets/img/tools.png';
import ContinueArrow from '../assets/img/diagram/continue-arrow.png';


export default function HomeView() {
    const navigate = useNavigate();

    const happyChick = useRef(null);
    const sadChick = useRef(null);
    const klusChicken = useRef(null);

    const [imageClass, setImageClass] = useState('slide-in image-slide');

    useEffect(() => {
        const interval = setInterval(() => {
            setImageClass(prevClass =>
                prevClass.includes('slide-in') ? 'slide-out image-slide' : 'slide-in image-slide'
            );
        }, 5000);  // toggle the image class every 5 seconds

        // Clear interval on component unmount
        return () => {
            clearInterval(interval);
        };
    }, []);

    const handleImageClick = () => {
        navigate('/target-page');
    };

    function chick(id) {
        if (id === 1) {
            happyChick.current.className = happyChick.current.className + ' big-button-active';
            setTimeout(() => {
                SoundController.beep();
                navigate('/goodkip');
            }, 250);

        } else if (id === 2) {
            sadChick.current.className = sadChick.current.className + ' big-button-active';
            setTimeout(() => {
                SoundController.beep();
                navigate('/sadkip');
            }, 250);

        } else if (id === 3) {
            klusChicken.current.className = klusChicken.current.className + ' long-button-active';
            setTimeout(() => {
                SoundController.beep();
                navigate('/kluskip');
            }, 250);
        }
    }

    //run randomly sound
    useEffect(() => {
        // Function to play the sound
        const playSound = () => {
            SoundController.idling(); // Your function to play the sound
        };

        // Function to set a random timeout
        const setRandomTimeout = () => {
            const randomTime = Math.random() * 120000; // Random time in milliseconds up to 2 minutes
            return setTimeout(playSound, randomTime);
        };

        // Set the initial timeout
        let timeoutId = setRandomTimeout();

        // Clear the timeout when the component is unmounted or re-rendered
        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <>
            <img src={HomeBG} className="" />
            <div className='header'>
                <div className='title text-center'>
                    How are you<br />
                    <span className="important-text">feeling</span> today?
                </div>
                <div className='subtitle text-center'>
                    Your privacy is important to us. And no worries, all answers will be treated confidentially.
                </div>
            </div>

            <div className="flex justify-center mt-20">
                <div className='big-button mr-6' onClick={() => chick(1)} ref={happyChick}>
                    <HappyChick className="chick-1" />
                    <p className='big-button-text'>HAPPY</p>
                </div>

                <div className='big-button ml-6' onClick={() => chick(2)} ref={sadChick}>
                    <SadChick className="chick-2" />
                    <p className='big-button-text sad-text'>SAD</p>
                </div>
            </div>

            <div className="flex justify-center mt-16">
                <div className='long-button flex' onClick={() => chick(3)} ref={klusChicken}>
                    <div className='col-9'>
                        <p className='long-button-text'>i need something fixed</p>
                    </div>
                    <div className='col-3'>
                        <img src={Tools} className="tools" />
                    </div>
                </div>
            </div>


            {/* CONFESS IS HIDDEN DUE TO NO DESIGN */}

            {/* <img
                src={Confess}
                className={imageClass}
                onClick={handleImageClick}
                alt="Continue"
            /> */}

            <img src={WinBG} className="win-bg" />
            <img src={WinEgg} className="win-egg" />
            <p className="win-text text-center">
                <b>ENTER</b><br />
                FOR A CHANCE TO<br />
                WIN THE <b>GOLDEN EGG</b>
            </p>

        </>
    );
}

