import axios from "axios";

const instance = axios.create({
  baseURL: "https://be.kiosk.upgrade-estate.be",
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    config.headers["X-Api-Key"] = 'azerty'; 
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;