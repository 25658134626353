import { useEffect } from "react";
import "../assets/css/thanks.css";
import { useNavigate } from "react-router-dom";

//images
import { ReactComponent as HappyChick } from '../assets/img/happy.svg';
import ThanksBG from '../assets/img/bg/thanks-bg.png';
import ThanksChick from '../assets/img/thanks-chick.png';
import StripesBG from '../assets/img/bg/stripes.png';

// controllers
import SoundController from '../controllers/sound.controller';

export default function ThanksComponent({ chick }) {

    const navigate = useNavigate();

    useEffect(() => {
        // Set a timeout to refresh the page every 2 minutes (120000 milliseconds)
        const timeoutId = setTimeout(() => {
            //navigate("/")
            // auto go to the lost/won page
            
        }, 60000); // 60000ms = 1 minute
        
        // Clear the timeout if the component is unmounted before the timeout fires
        return () => clearTimeout(timeoutId);
      }, []);  // Empty dependency array means this useEffect runs once, similar to componentDidMount


    // play sound when loaded
    useEffect(() => {
        setTimeout(() => {
            SoundController.thanks();
        }, 1000);
    }, []);

    return (
        <>
            <img src={ThanksBG} className="" />
            <img src={ThanksChick} className="thanks-chick" onClick={() => chick()} />

            <div className='header header-thanks'>
                <div className='title text-center'>
                    thanks for sharing!<br />
                    you are <span className="important-text">the best</span>
                </div>
                <div className='subtitle subtitle-thanks text-center'>
                Tap the chicken to see if you won<br/>
                the golden egg
                </div>
            </div>
            <img src={StripesBG} className="stripes-bg stripes-bg-thanks" />
        </>
    );
}