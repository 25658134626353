import { useState, useEffect, useRef } from 'react'
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../assets/css/sad.css';

//controllers
import SoundController from '../controllers/sound.controller';

//services
import SubmitService from '../services/submit.service';

//components
import ScanComponent from '../components/scan/scan.component';
import RoomNumberComponent from '../components/scan/roomnumber.component';
import ThanksComponent from '../components/thanks.component';
import LostComponent from '../components/lost.component';
import WonComponent from '../components/won.component';

//data stores
import { badgeKeyState } from '../data/badgeKey.atom';
import { loadingState } from '../data/loading.atom';
import { errorMessageState } from '../data/errorMessage.atom';

//images
import BotheringBG from '../assets/img/bg/bothering-bg.png';
import ContinueArrow from '../assets/img/diagram/continue-arrow.png';
import BackArrow from '../assets/img/diagram/back-arrow.png';

export default function SadKipView() {
    const [step, setStep] = useState(0)
    const [badgeKey, setBadgeKey] = useRecoilState(badgeKeyState);
    const [roomNumber, setRoomNumber] = useState(null)
    const [comment, setComment] = useState("")
    const [reload, setReload] = useState(0)

    const [bothering, setBothering] = useState("")
    const [customBothering, setCustomBothering] = useState("")

    const botheringInput = useRef(null);
    const botheringSelect = useRef(null);

    // win logic
    const [itemWon, setItemWon] = useState(null)

    const [loading, setLoading] = useRecoilState(loadingState);
    const [errorMessage, setErrorMessage] = useRecoilState(errorMessageState);

    const navigate = useNavigate();


    const whysad = [
        {
            id: 1,
            title: 'I don’t feel well personally',
            description: 'We are sorry to hear you don’t feel well these days.<br/>If you would have to rate your personal happiness from 1 – 10, what score would you give?',
            subdescription: 'Did you know Upkot offered a free session with a psychologist?<br/>Because we care about your mental health.<br/><br/>Send an email to feelfreetotalk@upkot.be if you would like to book a free session. '
        },
        {
            id: 2,
            title: 'I don’t like the general atmosphere at Upkot',
            description: 'Damn, we are sad to hear that.<br/>Would you like to tell us why?'
        },
        {
            id: 3,
            title: 'No fun activities',
            description: 'Damn, we are sad to hear that.<br/>Do you have some crazy ideas or events in mind to boost our Upkot community?'
        },
        {
            id: 4,
            title: 'Too much noise',
            description: 'Damn, we are sad to hear that.<br/>Would you like to tell us a little more about it (time, location, …)?'
        },
        {
            id: 5,
            title: 'The general maintenance of this building',
            description: 'Damn, we are sad to hear that.<br/>Could you specify what the exact problem(s) is/are so we could help out?'
        },
        {
            id: 6,
            title: 'Our internet connection',
            description: 'Damn, we are sad to hear that.<br/>Could you specify what the problem is?',
            subdescription: 'Did you know you could contact our internet supplier Smarteye/Studenteninternet (during working hours) via +32 9 395 60 00 or support@studenteninternet.be?'
        },
        {
            id: 7,
            title: 'Your safety',
            description: 'Damn, our basic need is to make you feel safe at Upkot, so it hurts us to read this is not the case for you.<br/>Please tell us more.'
        }
    ]


    function changeBothering(value) {
        setBothering(value)
        setCustomBothering("")
        botheringInput.current.value = ""
        botheringSelect.current.checked = false
    }

    useEffect(() => {
        if (badgeKey.length === 8) {
            setLoading("yes")
            const botheringc = customBothering ? customBothering : bothering

            SubmitService.push("SAD", botheringc, comment, badgeKey, roomNumber)
                .then((response) => {
                    console.log(response)
                    setLoading("no")
                    if (response.data.message === "OK") {
                        var resp = response.data
                        if (resp.won == "True") {
                            setItemWon(resp.item)
                        }
                        setStep(3)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setLoading("error")
                    setErrorMessage(error.response.data.message)
                })
        }
    }, [badgeKey, reload])


    function thanksChick() {
        SoundController.beep();
        if (itemWon) {
            setStep(5)
        } else {
            setStep(4)
        }
    }

    function RoomNumberFunc(room) {
        setRoomNumber(room)
        setStep(2)
    }

    function clearSession() {
        window.location.href = "/"
    }

    useEffect(() => {
        let timeoutId;

        if (step === 4 || step === 5) {
            timeoutId = setTimeout(() => {
                clearSession();
            }, 30000);
        } else if (step === 3) {
            timeoutId = setTimeout(() => {
                if (itemWon) {
                    setStep(5);
                } else {
                    setStep(4);
                }
            }, 20000);
        } else if (step === 0 || step === 1 || step === 2 || step === 6 || step === 7) {
            timeoutId = setTimeout(() => {
                clearSession();
            }, 60000);
        }

        // Cleanup function
        return () => {
            clearTimeout(timeoutId);
        };
    }, [step]);


    return (
        <>
            {step === 0 &&
                <>
                    <img src={BotheringBG} className="bothering-bg" />

                    <div className='header header-happy'>
                        <div className='title text-center title-happy'>
                            What's bothering<br />
                            you <span className="important-text">today?</span>
                        </div>
                    </div>

                    <div className="sad-form">
                        {whysad.map((botheringOption, index) => {
                            return (
                                <div className={`flex ${bothering == botheringOption.title ? "selectedBothering" : ""}`} onClick={() => changeBothering(botheringOption.title)}>
                                    <span id={`${botheringOption.title}-id`} className="selector">
                                        <span className="selector-inside"></span>
                                    </span>
                                    {botheringOption.title}
                                </div>
                            )
                        })}
                        <div className={`flex ${bothering == "Other" ? "selectedBothering" : ""}`} onClick={() => changeBothering("Other")}>
                            <span id="Other-id" className="selector">
                                <span className="selector-inside"></span>
                            </span>
                            <input
                                type="text"
                                value={customBothering}
                                onChange={(e) => setCustomBothering(e.target.value)}
                                placeholder="Other"
                                className="border-2 border-black custom-input"
                                ref={botheringInput}
                                onFocus={() => botheringSelect.current.checked = true}
                            />
                        </div>
                    </div>

                    <div className="flex justify-center button-block button-block-sad">
                        <div className='back-button' onClick={() => navigate("/")}>
                            <img src={BackArrow} className="continue-arrow" />
                            <p className='back-button-text'>previous</p>
                        </div>
                        <div className='continue-button' onClick={() => setStep(1)}>
                            <p className='continue-button-text'>continue</p>
                            <img src={ContinueArrow} className="continue-arrow" />
                        </div>
                    </div>
                </>
            }


            {step === 1 &&
                <>
                    <img src={BotheringBG} className="bothering-bg" />

                    <div className='header header-happy'>
                        <div className='title text-center title-happy'>
                            What's bothering<br />
                            you <span className="important-text">today?</span>
                        </div>
                    </div>

                    <div className="justify-center text-center content">
                        <div className='subtext w-4/5 ml-auto mr-auto'>
                            {bothering != "Other" ?
                                <div dangerouslySetInnerHTML={{ __html: whysad.find(x => x.title === bothering).description }}></div>
                                : "Tell us what's on your mind!"
                            }
                        </div>
                        <textarea
                            type="text"
                            className="input-comment mt-12"
                            placeholder="Start typing..."
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </div>

                    <div className="flex justify-center mt-16 button-block">
                        <div className='back-button' onClick={() => setStep(0)}>
                            <img src={BackArrow} className="continue-arrow" />
                            <p className='back-button-text'>previous</p>
                        </div>
                        <div className='continue-button' onClick={() => setStep(7)}>
                            <p className='continue-button-text'>continue</p>
                            <img src={ContinueArrow} className="continue-arrow" />
                        </div>
                    </div>

                    {
                        whysad.find(x => x.title === bothering && (x.id === 1 || x.id === 6)) &&
                        <div className="justify-center text-center content">
                            <div className='subtext w-4/5 ml-auto mr-auto mt-60'>
                                <p className='text-lg'>
                                    <div dangerouslySetInnerHTML={{ __html: whysad.find(x => x.title === bothering).subdescription }}></div>
                                </p>
                            </div>
                        </div>
                    }
                </>
            }

            {step === 2 &&
                <div>
                    <ScanComponent />
                </div>
            }

            {step === 3 &&
                <div>
                    <ThanksComponent chick={thanksChick} />
                </div>
            }

            {step === 4 &&
                <div>
                    <LostComponent />
                </div>
            }

            {step === 5 &&
                <div>
                    <WonComponent item={itemWon} />
                </div>
            }

            {/* {step === 6 &&
                <div>
                    <BadgeComponent badgeNumberFunc={badgeNumberFunc} badgeId={badgeKey} />
                </div>
            } */}

            {step === 7 &&
                <div>
                    <RoomNumberComponent RoomNumberFunc={RoomNumberFunc} />
                </div>
            }
        </>
    )
}