import React from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import { useLocation } from 'react-router-dom';

//views
import HomeView from './views/home.view';
import GoodKipView from './views/goodkip.view';
import SadKipView from './views/sadkip.view';
import KlusKipView from './views/kluskip.view';

//components
import Loader from './components/loader';

function App() {
  const searchParams = new URLSearchParams(useLocation().search);

  return (
    <>
      <Loader />
      <Routes>
        <Route path="/" element={<HomeView />} />
        <Route path="/goodkip/" element={<GoodKipView />} />
        <Route path="/sadkip/" element={<SadKipView />} />
        <Route path="/kluskip/" element={<KlusKipView />} />
        <Route path="/not_found/" element={<h1>Not found</h1>} />

        <Route path="*" element={<h1>Not found</h1>} />
      </Routes>
    </>
  )
}

export default App;
