import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { loadingState } from '../data/loading.atom';
import { errorMessageState } from '../data/errorMessage.atom';

//import images
import HappyChick from '../assets/img/happy.svg';
import SadChick from '../assets/img/sad.svg';

//import controllers
import SoundController from '../controllers/sound.controller';

function Loader() {
  const isLoading = useRecoilValue(loadingState);
  const errorMessage = useRecoilValue(errorMessageState);

  useEffect(() => {
    if (isLoading == "yes") {
      // SoundController.loading();
    } else if (isLoading == "error") {
      // SoundController.error();
    }

    // reload after 5 seconds
    if (isLoading == "error") {
      setTimeout(() => {
        window.location.href = "/";
      }, 5000);
    }

  }, [isLoading])

  return (
    isLoading == "yes" ? (
      <div style={overlayStyles}>
        <img
          src={HappyChick}
          alt="Happy Chick"
          style={{
            width: '20%',
            height: '20%',
            top: 0,
            left: 0,
            zIndex: 1001,
          }}
        />
      </div>
    ) : isLoading == "error" ? (
      <div style={overlayStyles}>
        <img
          src={SadChick}
          alt="Happy Chick"
          style={{
            width: '20%',
            height: '20%',
            top: 0,
            left: 0,
            zIndex: 1001,
          }}
        />
        <h1 
          // put the error message in the center of the screen, under the image
          style={{
            position: 'absolute',
            top: '57%',
            left: '50%',
            transform: 'translate(-50%, 0%)',
            color: 'white',
            zIndex: 1001,
          }}
          className='text-center text-3xl font-extrabold'
        >
          {errorMessage}
        </h1>

      </div>
    ) : (
      <></>
    )
  );
}

const overlayStyles = {
  position: 'fixed',
  width: '1080px',
  height: '1920px',
  top: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.75)',  // semi-transparent background
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,  // high z-index to ensure it's on top
};

export default Loader;